import React from "react";

import { StaticQuery, graphql } from "gatsby";
import Link from "gatsby-link";
import PropTypes from "prop-types";

import NextIco from "@assets/images/next.svg";


const News = ({ showCount }) => (
    <StaticQuery
        query={graphql`
      query NewsQuery {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
          totalCount
          edges {
            node {
              id
              frontmatter {
                title
                type
                date(formatString: "DD/MM/YYYY")
              }
              fields {
                slug
              }
              excerpt
            }
          }
        }
      }
    `}
        render={(data) => (
            <>
                {showCount && <h4>{data.allMarkdownRemark.totalCount} noticias</h4>}
                <div className="columns is-multiline is-mobile is-centered">
                    {data.allMarkdownRemark.edges.map(({ node }, i) => {
                        return (
                            <>
                                {/* {JSON.stringify(node)} */}
                                <Link to={node.fields.slug} key={node.id}
                                    className="news-box column is-3-desktop is-2-widescreen is-12-mobile">
                                    <h3 className="news-box__title">{node.frontmatter.title} </h3>
                                    <p>{node.excerpt}</p>
                                    <button>
                                        LER MAIS <img src={NextIco} />
                                    </button>
                                </Link>
                                {/* {(i+1) % 3 === 0 ? <div className="column is-12"></div>: <></>} */}
                            </>
                        );
                    })}
                </div>
            </>
        )}
    />
);
News.propTypes = {
    showCount: PropTypes.bool,
};

News.defaultProps = {
    showCount: false,
};
export default News;
